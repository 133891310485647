import React from "react"
import Layout from "../layouts/de"
import Seo from "../components/seo"
import SimplePage from "../templates/simple-page"

const PrivacyPage = () => {
    return (
        <Layout>
            <Seo id="imprint" />
            <SimplePage title="Impressum">
                <address>
                    <strong>Fritz Finkernagel</strong><br />
                    <strong>Drahtwerk GmbH &amp; Co. KG</strong><br />
                    Westiger Straße 120-128<br />
                    D-58762 Altena
                </address>

                <p>
                    Telefon: +49 (0) 2352/ 703-0<br />
                    Telefax: +49 (0) 2352/ 75447<br />
                    E-Mail: <a href="mailto:info@finkernagel.com">info@finkernagel.com</a>
                </p>

                <dl>
                    <dt>Geschäftsführer</dt>
                    <dd>Fritz Uwe Finkernagel, Timo Finkernagel</dd>

                    <dt>Persönlich haftende Gesellschafter</dt>
                    <dd>Fritz Finkernagel Drahtwerk GmbH</dd>

                    <dt>Handelsregister</dt>
                    <dd>Amtsgericht Iserlohn - HRA 3816 / HRB 5169</dd>

                    <dt>Zuständige Kammer</dt>
                    <dd>SIHK Hagen</dd>

                    <dt>Umsatzsteuer-Identifikationsnummer</dt>
                    <dd>DE 123842481</dd>

                    <dt>D-U-N-S-Nummer</dt>
                    <dd>325841021</dd>

                    <dt>EORI-Nummer</dt>
                    <dd>DE 4527372</dd>

                    <dt>Redaktionell verantwortlich</dt>
                    <dd>Timo Finkernagel, Geschäftsführer</dd>
                </dl>
            </SimplePage>
        </Layout>
    )
}

export default PrivacyPage
